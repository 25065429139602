import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { Redemption } from '@/api/wps/BackplaneModels';
import { GetRedemptionsErrHandlers } from '@/api/wps/ErrHandlers';
import { DateRange, ModuleWithDates, ModuleWithLoading } from '@/store/modules/.deprecated/baseModules';
import backplaneClient from '@/api/backplane/BackplaneClient';
import moment from 'moment/moment';
import { config } from '@/utils/config';
import { ResponseGetRedemptions } from '@/api/backplane/BackplaneModels';

export interface RedemptionOrdered extends Redemption {
  i: number;
}

@Module({
  dynamic: true,
  store,
  name: 'report-redemption',
  namespaced: true,
})
class RedemptionReportModule extends VuexModule {
  /* DATA */

  public nested = new (ModuleWithDates(ModuleWithLoading(Object)))();
  private redemptions: RedemptionOrdered[] = [];
  private limit: number = config.ReportsLimit;

  /* GETTERS */

  get Redemptions() {
    return this.redemptions;
  }

  /* MUTATIONS */

  @Mutation
  private appendRedemptions(redemptions: Redemption[]) {
    this.redemptions.push(...redemptions.map((r, i) => ({ ...r, i })));
  }
  @Mutation
  private dropRedemption(i: number) {
    const arr = this.redemptions;
    arr.splice(i, 1);
    for (let j = i; j < arr.length; ++j) {
      arr[j].i = j;
    }
  }
  @Mutation
  private resetRedemptions() {
    this.redemptions = [];
  }

  @Mutation
  protected setFromDate(fromDate: moment.Moment | null) {
    this.nested.setFromDate(fromDate);
  }
  @Mutation
  protected setUntilDate(untilDate: moment.Moment | null) {
    this.nested.setUntilDate(untilDate);
  }

  @Mutation
  protected startLoading() {
    this.nested.startLoading();
  }
  @Mutation
  protected finishLoading() {
    this.nested.finishLoading();
  }

  @Mutation
  private setClearState() {
    this.redemptions = [];
    this.nested.setFromDate(null);
    this.nested.setUntilDate(null);
    this.nested.clearLoading();
  }

  /* ACTIONS */

  @Action
  async changeDates(payload: { username: string; range: DateRange; errs?: GetRedemptionsErrHandlers }) {
    const { username, range, errs } = payload;
    let smthChanged = false;
    const newDates = this.nested.datesToChange(range);

    if (newDates.fromDate !== undefined) {
      smthChanged = true;
      this.setFromDate(newDates.fromDate);
    }
    if (newDates.untilDate !== undefined) {
      smthChanged = true;
      this.setUntilDate(newDates.untilDate);
    }

    if (smthChanged) {
      await this.searchRedemptions({ username, errs });
    }
  }

  @Action
  async searchRedemptions(payload: { username: string; errs?: GetRedemptionsErrHandlers }) {
    const { username, errs } = payload;
    const fromDate = this.nested.FromDate ? this.nested.FromDate.toISOString() : '';
    const untilDate = this.nested.UntilDate ? this.nested.UntilDate.toISOString() : '';

    let start = 0;
    const redemptions: Redemption[] = [];
    let loop = true;

    while (loop) {
      await this.nested.do.call(this, async () => {
        const query = {
          operator_id: username,
          redeem_date_from: fromDate,
          redeem_date_until: untilDate,
          limit: this.limit,
          start, // offset
        };

        const data = await backplaneClient.getRedemptions(query, errs).toPromise();

        if (data.redemptions) {
          start += data.redemptions.length;
          redemptions.push(...data.redemptions);
          if (data.redemptions.length < this.limit) loop = false;
        }
      });
    }

    this.resetRedemptions();
    this.appendRedemptions(redemptions);
  }

  @Action
  clearState() {
    this.setClearState();
  }

  /**
   * initialize loads the previous 10d of redemptions for the user
   * @param username
   */
  @Action
  async initialize(username: string) {
    this.clearState();
    await this.changeDates({
      username: username,
      range: {
        fromDate: moment().subtract(10, 'days'),
        untilDate: moment(),
      },
    });
  }
}

export function getRedemptionReportModule() {
  return getModule(RedemptionReportModule, store);
}
