








































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { constants } from '@/utils/constants';
import {
  optionalNumberMinimumRules,
  requiredFieldRules,
  requiredMultiSelectFieldRules,
  requiredNumberFieldRules,
} from '@/utils/validation-rules';
import { productModule } from '@/store/modules/moduleProduct';
import _ from 'lodash';
import RdmCollapsableCard from '@/components/common/card/RdmCollapsableCard.vue';
import {
  Prices,
  TaxesAndFees,
  TravelerPrices,
  OppPricing,
  OppPricingAmount,
  OppPricingTaxesForPricing,
  ProductLevel,
} from '@/api/inventory/InventoryModels';
import { inventoryModule } from '@/store/modules/.deprecated/moduleInventory';
import faker from 'faker';
import { arrayMove } from '@/utils/helpers';
import RdmReturnToBtn from '@/components/common/button/RdmReturnToBtn.vue';
import { appModule } from '@/store/modules/moduleApp';
import { travelerTypeModule } from '@/store/modules/moduleTravelerType';
import { optionModule } from '@/store/modules/moduleOption';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';
import { codes } from '@/utils/codeConstants';
import { isValid, parseISO } from 'date-fns';

@Component({
  components: {
    RdmCollapsableCard,
    RdmReturnToBtn,
  },
})
export default class PricingCreate extends Vue {
  private routerTaxesAndFeesViewEdit = constants.routes.TAXES_AND_FEES_EDIT;
  private routerTaxesAndFeesViewCreate = constants.routes.TAXES_AND_FEES_CREATE;
  private routerPricingList = constants.routes.PRICING_LIST;
  private parentName = constants.titles.PRICING;
  private saving = false;
  private errorState = false;
  private addTravelerTypeModal = false;
  private pricingForm = false;
  private displayTaxes = false;
  private corePrice = {} as any;
  private core = {} as any;
  private requiredField = requiredFieldRules;
  private requiredNumberField = requiredNumberFieldRules;
  private requiredMultipleSelect = requiredMultiSelectFieldRules;
  private optionalMinNumber = optionalNumberMinimumRules;
  private minimumPrice = 0;
  private selectedOption = '';
  private selectedProduct = '';
  private travelerPrices = { travelers: [] } as TravelerPrices;
  private dateFrom = '';
  private dateUntil = '';
  private taxesAndFees = [] as Array<TaxesAndFees>;
  private selectedTnF = [] as Array<TaxesAndFees>;
  private appliedTnF = [] as Array<TaxesAndFees>;
  private days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  private selectedCurrency = 'USD';
  private taxAndFeesHeaders: Array<Record<string, any>> = [
    { text: 'Name', value: 'name', align: 'left', sortable: false },
    { text: 'Type', value: 'chargeType', align: 'left', sortable: false },
    { text: 'Rate', value: 'rateType', align: 'left', sortable: false },
    { text: 'Calculation', value: 'applicationLevel', align: 'left', sortable: false },
    {
      text: 'Effective Dates',
      value: 'effectiveDateRange',
      align: 'left',
      sortable: false,
    },
    { text: '', value: 'view', align: 'right', sortable: false },
  ];

  private async mounted() {
    await httpModule.getTaxesAndFees();
    await httpModule.getTravelerTypes();
    this.travelerPrices = _.cloneDeep(this.GlobalTravelerPrices);
    this.taxesAndFees = inventoryModule.TaxesAndFees;
    if (
      appModule.PreviousRoute &&
      appModule.PreviousRoute.name &&
      appModule.PreviousRoute.name === constants.routes.PRODUCT_DETAILS &&
      !_.isEmpty(appModule.PreviousRoute.params) &&
      appModule.PreviousRoute.params.id
    ) {
      this.selectedProduct = appModule.PreviousRoute.params.id;
    }
  }

  /* METHODS */

  private changeProduct() {
    // todo filter options?
  }

  private formattedCurrencies() {
    return this.Currencies.map((item: any) => {
      return { ...item, itemText: `${item.description} (${item.value})` };
    });
  }

  private applyTaxesAndFees() {
    this.taxesAndFees = this.taxesAndFees.filter(
      (x) => !this.selectedTnF.some((y) => x.id === y.id),
    );
    this.appliedTnF = [...this.selectedTnF, ...this.appliedTnF];
    this.selectedTnF = [];
  }
  private removeAppliedTax(tax: TaxesAndFees) {
    this.taxesAndFees = [...this.taxesAndFees, tax];
    this.selectedTnF = [];
    this.appliedTnF = this.appliedTnF.filter((item) => item.id !== tax.id);
  }

  private moveAppliedUp(index: number) {
    this.appliedTnF = arrayMove(this.appliedTnF, index, index - 1);
  }

  private moveAppliedDown(index: number) {
    this.appliedTnF = arrayMove(this.appliedTnF, index, index + 1);
  }

  private navigateToNewTaxAndFee() {
    this.$router
      .push({
        name: this.routerTaxesAndFeesViewCreate,
        params: { isPricing: 'true' },
      })
      .catch(() => {});
  }

  private discardChanges() {
    // todo: display discard changes alert if form has been changed
    this.$router.push({ name: this.routerPricingList }).catch(() => {});
  }

  private prepareDataToSubmit() {
    const appliedTaxes = this.appliedTnF.map((item, index) => {
      return {
        name: item.name,
        taxFeeId: item.id,
        applicationOrder: index + 1,
      } as OppPricingTaxesForPricing;
    });
    for (let i = 0; i < this.TravelerPrices.travelers.length; i++) {
      const { original, net, retail } = this.TravelerPrices.travelers[i].default;
      if (Number(original) > 0 || Number(net) > 0 || Number(retail) > 0) {
        this.saveData(
          this.TravelerTypes[i].id,
          {
            original: Number(original),
            net: Number(net),
            retail: Number(retail),
          },
          appliedTaxes,
        );
      }
    }
  }

  private saveData(
    unitId: string | undefined,
    prices: Prices,
    appliedTaxes: OppPricingTaxesForPricing[],
  ) {
    const pricing = {} as OppPricing;

    if (!companyModule.Organization || !companyModule.Organization.ID) {
      // todo throw error missing ORG ID
      appModule.addMessageError(codes.GENERAL_MESSAGE_NO_ORG_ID);
      return;
    }

    if (!companyModule.Company || !companyModule.Company.orgId) {
      // todo throw error missing supplier ID
      appModule.addMessageError(codes.GENERAL_MESSAGE_NO_SUPPLIER_ID);
      return;
    }

    pricing.id = faker.datatype.uuid();
    pricing.orgId = companyModule.Organization.ID;
    pricing.supplierId = companyModule.Company.orgId;
    pricing.productId = this.selectedProduct;
    if (this.selectedOption && this.selectedOption.length > 0) {
      pricing.optionId = this.selectedOption;
    }

    if (unitId && unitId.length > 0) {
      pricing.unitIds = [unitId];
    }

    const netAmount = prices.net * 100;
    const originalAmount = prices.original * 100;
    const retailAmount = prices.retail * 100;
    pricing.amount = {
      net: netAmount,
      original: originalAmount,
      retail: retailAmount,
      currency: this.selectedCurrency,
    } as OppPricingAmount;

    pricing.appliedTaxesFees = appliedTaxes;

    const fromDate = isValid(this.dateFrom) ? parseISO(this.dateFrom) : undefined;
    if (fromDate) {
      const untilDate = isValid(this.dateUntil) ? parseISO(this.dateUntil) : undefined;
      pricing.effectiveDateRange = {
        from: fromDate.toISOString(),
        until: untilDate ? untilDate.toISOString() : undefined,
      };
    }

    pricing.level = _.isEmpty(pricing.optionId)
      ? ProductLevel.Product
      : ProductLevel.Combined;
    pricing.timeslots = []; // todo add timeslot section

    this.saving = true;
    inventoryModule
      .createPricing(pricing)
      .then(() => {
        this.navigateToPriceList();
      })
      .catch(() => {
        // todo display try again alert?
      })
      .finally(() => {
        this.saving = false;
      });
  }

  private navigateToPriceList() {
    this.$router.push({ name: constants.routes.PRICING_LIST }).catch(() => {});
  }

  private needMoreTaxes() {
    this.$router.push({ name: constants.routes.FEEDBACK }).catch(() => {});
  }

  private calculateTotal(amount: any): string {
    const percent = (amount / 100) * 5;
    const total = Number.parseInt(amount) + percent;
    return _.round(total, 2).toFixed(2);
  }

  get RouterName() {
    if (
      appModule.PreviousRoute &&
      appModule.PreviousRoute.name &&
      appModule.PreviousRoute.name === constants.routes.PRODUCT_DETAILS
    ) {
      return appModule.PreviousRoute.name;
    }
    return this.routerPricingList;
  }

  get ButtonText() {
    if (
      appModule.PreviousRoute &&
      appModule.PreviousRoute.name &&
      appModule.PreviousRoute.name === constants.routes.PRODUCT_DETAILS
    ) {
      return constants.titles.PRODUCT;
    }
    return constants.titles.PRICING;
  }

  get RouterParams() {
    if (
      appModule.PreviousRoute &&
      appModule.PreviousRoute.params &&
      !_.isEmpty(appModule.PreviousRoute.params) &&
      appModule.PreviousRoute.params.id
    ) {
      return { id: appModule.PreviousRoute.params.id };
    }
    return undefined;
  }

  get Loading(): boolean {
    return inventoryModule.Loading;
  }

  get Currencies() {
    return appModule.Codes?.currency || [];
  }

  get TravelerTypes() {
    return travelerTypeModule.TravelerTypes;
  }

  get TravelerPrices() {
    return this.travelerPrices;
  }

  get GlobalTravelerPrices() {
    return travelerTypeModule.TravelerPrices;
  }
  get IndependentOnlyApplied() {
    const independentApplied = this.appliedTnF.filter((item) => {
      return item.ruleType === 'INDEPENDENT';
    });
    return independentApplied.length === this.appliedTnF.length;
  }

  get Products() {
    return productModule.Products.sort((a, b) => {
      const upperA = a.internalName?.toUpperCase() || '';
      const upperB = b.internalName?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get Options() {
    return optionModule.Options.sort((a, b) => {
      const upperA = a.internalName?.toUpperCase() || '';
      const upperB = b.internalName?.toUpperCase() || '';

      if (upperA < upperB) {
        return -1;
      }

      if (upperA > upperB) {
        return 1;
      }

      return 0;
    });
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }
}
