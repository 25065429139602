export const constants = {
  /* Cookies */

  ACCESS_TOKEN: 'access-token',
  REFRESH_TOKEN: 'refresh-token',

  /* Org Types */

  ORG_TYPE_RC: 'RedeamCentral',
  ORG_TYPE_TS: 'TicketingSystem',

  /* Date Format types*/
  DEFAULT_TIMEZONE: 'UTC',

  dateFormatTypes: {
    HHMM: 'hh:mm a',
  },

  /* Local Storage */

  localstorage: {
    KNOWN_VERSION: 'rdm-known-version',
    GROUPON_SEARCH_SUPPLIER_ID: 'rdm-groupon-search-supplier-id',
    GROUPON_SEARCH_PRODUCT_ID: 'rdm-groupon-search-product-id',
    GROUPON_SEARCH_SUPPLIER_RESULT: 'rdm-groupon-search-supplier-result',
  },

  /* Session Storage */

  sessionstorage: {
    USER_MANAGEMENT_PAGE: 'rdm-user-mgmt-page',
    USER_MANAGEMENT_SELECTED_ROLE: 'rdm-user-mgmt-select-role',
  },

  /* Roles */

  roles: {
    SUPPLIER_BUSINESS_STAFF: 'SUPPLIER_BUSINESS_STAFF',
    SUPPLIER_REDEMPTION_STAFF: 'SUPPLIER_REDEMPTION_STAFF',
    SUPPLIER_REPORTING_STAFF: 'SUPPLIER_REPORTING_STAFF',
    SUPPLIER_REPORTING_ALL_BOOKING_STAFF: 'SUPPLIER_REPORTING_ALL_BOOKING_STAFF',
    SUPPLIER_REPORTING_CUSTOMER_MANIFEST_STAFF:
      'SUPPLIER_REPORTING_CUSTOMER_MANIFEST_STAFF',
    SUPPLIER_REPORTING_REDEMPTIONS_STAFF: 'SUPPLIER_REPORTING_REDEMPTIONS_STAFF',
    ORG_MGMT: 'ORG_MGMT',
    RC_CONNECTIVITY_MANAGEMENT: 'RC_CONNECTIVITY_MANAGEMENT',
    RC_TICKETING_VIEW: 'RC_TICKETING_VIEW',
    RC_TICKETING_EDIT: 'RC_TICKETING_EDIT',
    REDEAM_TECHNICAL_STAFF: 'REDEAM_TECHNICAL_STAFF',
  },

  /* Features */

  features: {
    ROUTING_WEB_REDEMPTION: 'ROUTING_WEB_REDEMPTION',
    ROUTING_USER_MAIN: 'ROUTING_USER_MAIN',
    ROUTING_HOME: 'ROUTING_HOME',
    ROUTING_COMPANY_MAIN: 'ROUTING_COMPANY_MAIN',
    ROUTING_PRODUCT_MAIN: 'ROUTING_PRODUCT_MAIN',
    ROUTING_CHANNEL_MAIN: 'ROUTING_CHANNEL_MAIN',
    ROUTING_REDEMPTION_OP: 'ROUTING_REDEMPTION_OP',
    ROUTING_ARRIVALS_OP: 'ROUTING_ARRIVALS_OP',
    ROUTING_GROUPON_PRICING_SUB: 'ROUTING_GROUPON_PRICING_SUB',
    ROUTING_RWG_DASHBOARD_OP: 'ROUTING_RWG_DASHBOARD_OP',
    ROUTING_REPORT_MAIN: 'ROUTING_REPORT_MAIN',
    ROUTING_REPORT_DAILY_OP: 'ROUTING_REPORT_DAILY_OP',
    ROUTING_REPORT_WEEKLY_OP: 'ROUTING_REPORT_WEEKLY_OP',

    SIDEBAR_REPORTS_GROUP_RWG_DASHBOARD_ITEM: 'SIDEBAR_REPORTS_GROUP_RWG_DASHBOARD_ITEM',
    SIDEBAR_WEB_REDEMPTION_ITEM: 'SIDEBAR_WEB_REDEMPTION_ITEM',
    SIDEBAR_USER_ITEM: 'SIDEBAR_USER_ITEM',
    SIDEBAR_COMPANY_ITEM: 'SIDEBAR_COMPANY_ITEM',
    SIDEBAR_REPORTS_GROUP_ITEM: 'SIDEBAR_REPORTS_GROUP_ITEM',
    SIDEBAR_REPORTS_GROUP_DAILY_OP_ITEM: 'SIDEBAR_REPORTS_GROUP_DAILY_OP_ITEM',
    SIDEBAR_REPORTS_GROUP_WEEKLY_OP_ITEM: 'SIDEBAR_REPORTS_GROUP_WEEKLY_OP_ITEM',
    SIDEBAR_REPORTS_GROUP_REDEMPTION_ITEM: 'SIDEBAR_REPORTS_GROUP_REDEMPTION_ITEM',
    SIDEBAR_REPORTS_GROUP_ARRIVAL_ITEM: 'SIDEBAR_REPORTS_GROUP_ARRIVAL_ITEM',
    SIDEBAR_PRODUCT_ITEM: 'SIDEBAR_PRODUCT_ITEM',
    SIDEBAR_CHANNEL_ITEM: 'SIDEBAR_CHANNEL_ITEM',
    SIDEBAR_GROUPON_GROUP_ITEM: 'SIDEBAR_GROUPON_GROUP_ITEM',
    SIDEBAR_GROUPON_GROUP_PRICING_ITEM: 'SIDEBAR_GROUPON_GROUP_PRICING_ITEM',
    SIDEBAR_ADMIN_GROUP_ITEM: 'SIDEBAR_ADMIN_GROUP_ITEM',
    SIDEBAR_ADMIN_GROUP_ORG_FEATURE_ITEM: 'SIDEBAR_ADMIN_GROUP_ORG_FEATURE_ITEM',
    SIDEBAR_ADMIN_GROUP_APP_FEATURE_ITEM: 'SIDEBAR_ADMIN_GROUP_APP_FEATURE_ITEM',
    SIDEBAR_HELP_CENTER_FEATURE_ITEM: 'SIDEBAR_HELP_CENTER_FEATURE_ITEM',

    WELCOME_WIZARD: 'WELCOME_WIZARD',
  },

  /* Event Listeners */

  events: {
    GUEST_TYPE_LOAD_COMPLETE: 'guest-type-load-complete',
    INIT_LOAD_COMPLETE: 'init-load-complete',
    OPTION_LOAD_COMPLETE: 'option-load-complete',
    OPTION_CHOOSE: 'option-choose',
    OPEN_FULLSCREEN_CALENDAR: 'open-fullscreen-calendar',
    PRODUCT_LOAD_COMPLETE: 'product-load-complete',
    REFRESH_PRODUCTS: 'refresh-products',
    RESIZE: 'resize',
    REDRAW_DEPARTURE_MAP: 'redraw-departure-map',
    CREATE_PRODUCT: 'create-product',
    CREATE_OPTION: 'create-option',
    CREATE_TRAVELER_TYPE: 'create-traveler-type',
    APPLY_TAXES_AND_FEES: 'apply-taxes-and-fees',
    EDIT_TRAVELER_TYPE: 'edit-traveler-type',
    OFFER_CREATE_FORM_4: 'offer-create-form-4',
    SELECT_CALENDAR_DAY: 'select-calendar-day',
    PRODUCT_LOCATION_EDIT_MODAL_CLEAR_VALIDATION:
      'product-location-edit-modal-clear-validation',
    OPTION_LOCATION_EDIT_MODAL_CLEAR_VALIDATION:
      'option-location-edit-modal-clear-validation',
    INVENTORY_EDIT_SAVE: 'inventory-edit-save',
    INVENTORY_EDIT_REMOVE: 'inventory-edit-remove',
    INVENTORY_HOME_MODAL_CLOSE: 'inventory-home-modal-close',
  },

  /* Route Names */

  routes: {
    // public
    LOGIN: 'login',
    NOT_FOUND: 'not-found',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset-password',

    // public wip
    REGISTER: 'register',
    ACCOUNT_REQUESTED: 'accountRequested',
    AVAILABILITY: 'availabilityCalendar',

    // private
    BASE: 'base',
    HOME: 'home',
    REDEMPTION: 'redemption',
    PROFILE: 'user-profile',

    // private, users
    USERS: 'users',
    USER_DETAILS: 'user-details',
    USER_DETAILS_VIEW: 'user-details-view',

    // private, company
    COMPANY: 'company-info',
    LOCATIONS: 'company-location-listings',
    CONTACTS: 'company-contacts',
    CONTACTS_ADD: 'company-contacts-add',
    CONTACTS_EDIT: 'company-contacts-edit',
    CONTACTS_VIEW: 'company-contacts-view',
    COMPANY_EDIT: 'company-edit-info',
    COMPANY_ADD: 'company-add-info',
    LOCATION_DETAILS: 'company-location-details',
    LOCATION_ADD: 'company-location-add',
    LOCATION_EDIT: 'company-location-edit',
    LOCATION_VIEW: 'company-location-view',

    // private, products
    PRODUCT_LIST: 'products',
    OPTION_LIST: 'option-list-summary',
    TRAVELER_LIST: 'traveler-type-summary',
    TRAVELER_CREATE: 'create-traveler-type',
    TRAVELER_EDIT: 'edit-traveler-type',
    PRODUCT_DETAILS: 'product-details',
    PRODUCT_OPTIONS: 'product-options',
    PRODUCT_EDIT: 'product-edit',
    PRODUCT_CREATE: 'create-product',
    OPTION_CREATE: 'create-option',
    OPTION_DETAILS: 'option-details',
    OPTION_TIME_CAPACITY_ADD: 'option-time-capacity-add',
    OPTION_TIME_CAPACITY_EDIT: 'option-time-capacity-edit',
    OPTION_TIME_CAPACITY_VIEW: 'option-time-capacity',
    PRODUCT_TIME_CAPACITY_ADD: 'product-time-capacity-add',
    PRODUCT_TIME_CAPACITY_EDIT: 'product-time-capacity-edit',
    PRODUCT_TIME_CAPACITY_VIEW: 'product-time-capacity',

    // private, inventory
    PRICING_LIST: 'pricing',
    INVENTORY_AVAILABILITY: 'inventory-availability',
    INVENTORY_MANAGEMENT: 'inventory-management',
    AVAILABILITY_STATUS: 'availability-status',
    PRICING_VIEW: 'pricing-view',
    PRICING_DETAILS: 'pricing-details',
    PRICING_EDIT: 'pricing-edit',
    PRICING_CREATE: 'pricing-create',
    OFFER_LIST: 'offer',
    OFFER_VIEW: 'offer-view',
    OFFER_DETAILS: 'offer-details',
    OFFER_EDIT: 'offer-edit',
    OFFER_CREATE: 'offer-create',
    AVAILABILITY_DETAILS: 'inventory-availability-details',
    AVAILABILITY_EDIT: 'inventory-availability-edit',
    AVAILABILITY_CREATE: 'inventory-availability-create',
    TAXES_AND_FEES: 'taxes-and-fees',
    TAXES_AND_FEES_VIEW: 'taxes-and-fees-view',
    TAXES_AND_FEES_CREATE: 'taxes-and-fees-create',
    TAXES_AND_FEES_EDIT: 'taxes-and-fees-edit',
    FEEDBACK: 'feedback',

    // private, channels
    CHANNEL_LIST: 'channels',
    CHANNEL_AVAILABLE_LIST: 'available-channels',
    CHANNEL_RATE_CARDS: 'channel-rate-card-listing',
    CHANNEL_DETAILS: 'channel-details',
    CHANNEL_CONTRACT: 'channel-details-contract',
    CHANNEL_CONTRACT_RATE_DETAILS: 'channel-details-contract-rate-details',
    CHANNEL_RATE_CARD_DETAILS: 'channel-rate-card-details',
    CHANNEL_RATE_CARD_PRICING: 'channel-rate-card-pricing',
    CHANNEL_RATE_CARD_CREATE: 'channel-rate-card-create',
    CHANNEL_RATE_CARD_EDIT: 'channel-rate-card-edit',
    CHANNEL_RATE_CARD_VIEW: 'channel-rate-card-view',

    // private, reports
    REPORT_REDEMPTIONS: 'report-web-redemptions',
    REPORT_ARRIVALS: 'report-arrivals',
    REPORT_ALL_BOOKINGS: 'report-all-bookings',
    REPORT_CUSTOMER_MANIFEST: 'report-customer-manifest',

    // private, admin
    GROUPON_PRICING: 'groupon-pricing',
    ADMIN_APP_MNGMT: 'admin-app-management',
    ADMIN_APP_MNGMT_COMPANY: 'admin-app-management-company',

    // private, help
    HELP_CENTER: 'help-center',
  },

  /* Titles */
  titles: {
    ADMIN: 'Admin',
    ALL_BOOKINGS: 'All Bookings',
    AVAILABLE_CHANNELS: 'Available Channels',
    AVAILABILITY: 'Availability',
    ADD_INVENTORY: 'Add Inventory',
    AVAILABILITY_STATUS: 'Availability Status',
    CHANNEL: 'Channel',
    CHANNELS: 'Channels',
    COMPANY: 'Company',
    COMPANY_ABOUT: 'About',
    CONTACT: 'Contact',
    CONTACTS: 'Contacts',
    CONTACTS_ADD: 'Add Contact',
    CONTACTS_EDIT: 'Edit Contact',
    CONNECTED_CHANNELS: 'Connected Channels',
    CONNECTED_CHANNEL: 'Connected Channel',
    CUSTOMER_MANIFEST: 'Customer Manifest',
    HOME: 'Home',
    INVENTORY: 'Inventory',
    TAXES_AND_FEES: 'Taxes & Fees',
    TAXES_AND_FEES_ADD: 'Add Taxes & Fees',
    TAXES_AND_FEES_EDIT: 'Edit Taxes & Fees',
    LOCATION: 'Location',
    LOCATION_ADD: 'Add Location',
    LOCATION_EDIT: 'Edit Location',
    LOCATION_VIEW: 'Location',
    LOCATIONS: 'Locations',
    OFFER: 'Offers',
    OFFER_CREATE: 'Create Offer',
    OFFER_EDIT: 'Edit Offer',
    OFFER_VIEW: 'View Offer',
    OPTION: 'Option',
    OPTIONS: 'Options',
    OPTION_CREATE: 'Add Option',
    OPTION_TIME_CAPACITY_ADD: 'Add Time & Capacity',
    OPTION_TIME_CAPACITY_EDIT: 'Edit Time & Capacity',
    OPTION_TIME_CAPACITY_VIEW: 'Time & Capacity',
    PRICING: 'Pricing',
    PRICING_CREATE: 'Add Pricing',
    PRICING_EDIT: 'Edit Pricing',
    PRICING_INVENTORY: 'Pricing & Inventory',
    PRICING_VIEW: 'View Pricing',
    PRODUCT: 'Product',
    PRODUCTS: 'Products',
    PRODUCT_CREATE: 'Add Product',
    PRODUCT_TIME_CAPACITY_ADD: 'Add Time & Capacity',
    PRODUCT_TIME_CAPACITY_EDIT: 'Edit Time & Capacity',
    PRODUCT_TIME_CAPACITY_VIEW: 'Time & Capacity',
    RATE_CARD: 'Rate Card',
    RATE_CARD_ADD: 'Add Rate Card',
    RATE_CARDS: 'Rate Cards',
    RATE_CARDS_EDIT: 'Edit Rate Cards',
    REDEMPTION: 'Redemption',
    REDEMPTIONS: 'Redemptions',
    REPORT: 'Report',
    REPORTS: 'Reports',
    SETTINGS: 'Settings',
    TRAVELER_TYPE_CREATE: 'Create Traveler Type',
    TRAVELER_TYPE_VIEW: 'Traveler Type',
    TRAVELER_TYPE: 'Traveler Type',
    TRAVELER_TYPES: 'Traveler Types',
    USER: 'User',
    USERS: 'User Management',
    FEEDBACK: 'Feedback',
    HELP_CENTER: 'Help Center',
  },

  /* Endpoints and URL paths */
  endpoints: {
    COMPANIES: 'companies',
    SUPPLIERS: 'suppliers',
    PRODUCTS: 'products',
    ADDRESSES: 'addresses',
    AUDIT: 'audit',
  },

  messages: {
    AVAILABILITY_STATUS_GETTING_STARTED_NO_PRICE_INVENTORY:
      'Add pricing and inventory to start managing availability',
    AVAILABILITY_STATUS_GETTING_STARTED_NO_PRICE:
      'Add pricing to start managing availability',
    AVAILABILITY_STATUS_GETTING_STARTED_NO_INVENTORY:
      'Add inventory to start managing availability',
    RATE_CARD_GETTING_STARTED_NO_SEARCH_RESULTS:
      'Start a new search or add a new rate card to continue viewing',
    RATE_CARD_GETTING_STARTED_NO_RATE_CARD:
      'Add pricing to begin managing rate cards for channels',
    RATE_CARD_GETTING_STARTED_NO_PRICING_CARD:
      'Add rate cards to provide custom pricing and discounts for channels',
    UNAUTHORIZED_CHANNEL_API:
      'Please contact support@redeam.com to update your channel credentials',
  },

  /* Fields */
  fields: {
    PRODUCT_DESCRIPTION: 'product-description',
    PRODUCT_NAME: 'product-name',
  },
  /* Taxes*/
  TAX_FEE: 'Tax/Fee',
  // TODO

  /* User Management actions */
  ACTIVATE_USER: 'activateUser',
  ROLE_ADD: 'addRole',
  ROLE_REMOVE: 'removeRole',
  REMOVE_USER: 'removeUser',
} as const;

export type Role = keyof typeof constants.roles;
