import faker from 'faker';
import { OperatorLocation, OperatorLocationHours } from '@/models/OperatorLocation';
import { demoPermissionRoles } from '@/utils/demoData';
import moment from 'moment';
import { constants } from '@/utils/constants';
import { formatISO, isBefore } from 'date-fns';
import { Meta } from '@/models';
import _ from 'lodash';
import { appModule } from '@/store/modules/moduleApp';
import { CountryData } from '@/api/inventory/InventoryModels';
import { config } from '@/utils/config';
import { profileModule } from '@/store/modules/moduleProfile';
import { countryData } from '@/app-codes/countryData';

export function nonProductionConsoleLog(...data: any[]) {
  if (!config.IsProductionEnv || profileModule.IsAdmin) {
    console.log(...data);
  } else {
    // todo log message in backend
  }
}

export function formatDateToHHMM(date: string, timezone?: string): string {
  return moment
    .tz(date, timezone || constants.DEFAULT_TIMEZONE)
    .format(constants.dateFormatTypes.HHMM);
}

export function buttonTextRegardingToStatus(status: string): string {
  return status === 'ENABLED' ? 'Deactivate' : 'Activate';
}

export function compareNumbers(a: number, b: number): number {
  return a - b;
}

export function isTimestampBeforeTimestamp(a: string, b: string): boolean {
  const dateA = new Date(a);
  const dateB = new Date(b);
  return isBefore(dateA, dateB);
}

export function dollarsToCents(amount: number) {
  return parseInt(String(amount * 100));
}

export function centsToDollars(amount: number | null | undefined) {
  if (!amount) return null;
  return amount / 100;
}

export function percentToDecimal(amount: number) {
  return _.round((100 + amount) / 100, 2);
}

export function decimalToPercent(amount: number) {
  return _.round(amount * 100 - 100, 2);
}

export function zeroPrice(item: any) {
  return (
    (typeof item.net === 'string' && item.net.length && !Number(item.net)) ||
    (typeof item.original === 'string' &&
      item.original.length &&
      !Number(item.original)) ||
    (typeof item.retail === 'string' && item.retail.length && !Number(item.retail))
  );
}

export function generateProductPrice(
  total: number | undefined,
  tax: number,
  option: number,
) {
  return total ? centsToDollars(total - tax - option) : null;
}

export function generateOptionAmountInDollars(
  total: number | undefined,
  unitId: string,
  type: string,
  option: any,
) {
  if (!total) return;
  let price;
  if (option && option.unitPrices && _.isArray(option.unitPrices)) {
    price = option.unitPrices.find((optPrice: any) => optPrice.unitId === unitId);
  }
  if (price !== undefined) {
    switch (type) {
      case 'net':
        return centsToDollars(price.amount.net);
      case 'original':
        return centsToDollars(price.amount.original);
      case 'retail':
        return centsToDollars(price.amount.retail);
      default:
        return null;
    }
  } else return null;
}

export function generateTaxPrice(unit: any, type: string) {
  switch (type) {
    case 'net':
      return unit &&
        unit.amount &&
        unit.amount.net &&
        _.isArray(unit.amount.includedFees) &&
        unit.amount.includedFees.length > 0 &&
        unit.amount.includedFees[0].net
        ? unit.amount.includedFees[0].net
        : null;
    case 'original':
      return unit &&
        unit.amount &&
        _.isArray(unit.amount.includedFees) &&
        unit.amount.includedFees.length > 0 &&
        unit.amount.includedFees[0].original
        ? unit.amount.includedFees[0].original
        : null;
    case 'retail':
      return unit &&
        unit.amount &&
        _.isArray(unit.amount.includedFees) &&
        unit.amount.includedFees.length > 0 &&
        unit.amount.includedFees[0].retail
        ? unit.amount.includedFees[0].retail
        : null;
    default:
      return null;
  }
}

export function tablePaginationOptions(items: any[], length?: number) {
  if (length) return items.length && items.length <= length ? [] : [5, 15, 50, -1];
  return items.length && items.length <= 10 ? [] : [10, 25, 50, -1];
}

export function compareDateStrings(a: string, b: string): number {
  return moment(a).isBefore(moment(b)) ? -1 : 1;
}

export function arrayMove(array: any, oldIndex: number, newIndex: number) {
  const arr = [...array];
  while (oldIndex < 0) {
    oldIndex += arr.length;
  }
  while (newIndex < 0) {
    newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

export function compareStrings(a: string, b: string): number {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export function generateOperatorLocation(): OperatorLocation {
  const operatorLocation: OperatorLocation = new OperatorLocation();

  operatorLocation.companyName = faker.company.companyName();
  operatorLocation.locationContact.firstName = faker.name.firstName();
  operatorLocation.locationContact.lastName = faker.name.lastName();
  operatorLocation.locationContact.email = faker.internet.email();
  operatorLocation.locationContact.phone = faker.phone.phoneNumber();
  operatorLocation.locationDescription = faker.company.catchPhrase();
  operatorLocation.operatorAddress.street1 = faker.address.streetAddress(true);
  operatorLocation.operatorAddress.city = faker.address.city();
  operatorLocation.operatorAddress.zip = faker.address.zipCode();
  operatorLocation.operatorAddress.state = faker.address.state();
  operatorLocation.operatorAddress.country = faker.address.country();
  operatorLocation.operatorAddress.lat = faker.address.latitude();
  operatorLocation.operatorAddress.long = faker.address.longitude();

  return operatorLocation;
}

export function nameFormattingForDuplicatedItems(name: string): string {
  const copy = '-copy-';
  if (name.includes(copy)) {
    const version = Number(name.slice(-1));
    const cleanName = name.split('-')[0];
    return `${cleanName}${copy}${version + 1}`;
  } else {
    return `${name}${copy}1`;
  }
}

export function incrementMeta(meta?: Meta): Meta {
  const currentTime = formatISO(new Date());
  return {
    status: 'ENABLED',
    version: meta && meta.version ? meta.version + 1 : 1,
    createdAt:
      meta && meta.createdAt && meta.createdAt.length > 0 ? meta.createdAt : currentTime,
    updatedAt: currentTime,
  } as Meta;
}

export function incrementVersion(meta?: Meta | number): number {
  if (meta && typeof meta === 'number') return meta + 1;
  else if (meta && typeof meta === 'object') return meta.version ? meta.version + 1 : 1;
  else return 1;
}

export function generateUser() {
  const fname = faker.name.firstName();
  const lname = faker.name.lastName();
  return {
    id: faker.datatype.number(),
    first_name: fname,
    last_name: lname,
    contact_name: `${fname} ${lname}`,
    user_type: faker.random.arrayElement(demoPermissionRoles),
    location: 'Main', // location: faker.random.arrayElement(demoLocationNames),
    phone_country_code: faker.datatype.number(999),
    phone_number: faker.phone.phoneNumberFormat(1),
    email: faker.internet.email(fname, lname, 'sfbaycruises.com'),
    edit: 'VIEW AND EDIT',
    password: faker.internet.password(),
  };
}

export const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const knownRoles = Object.keys(constants.roles);

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function randomTime(): string {
  const h: number = faker.datatype.number({ min: 0, max: 23 });
  const m: string = faker.random.arrayElement(['00', '15', '30', '45']);
  return `${h}:${m}`;
}

export function randomMorningTime(): string {
  const h: number = faker.datatype.number({ min: 0, max: 12 });
  const m: string = faker.random.arrayElement(['00', '15', '30', '45']);
  return `${h}:${m}`;
}

export function randomEveningTime(): string {
  const h: number = faker.datatype.number({ min: 13, max: 23 });
  const m: string = faker.random.arrayElement(['00', '15', '30', '45']);
  return `${h}:${m}`;
}

export function generateOperatorLocationHours(): OperatorLocationHours[] {
  const hours: OperatorLocationHours[] = [];
  for (let i = 0; i < daysOfWeek.length; i++) {
    const oh = new OperatorLocationHours();
    oh.day_of_week = daysOfWeek[i];
    oh.open = randomMorningTime();
    oh.close = randomEveningTime();
    oh.active = faker.datatype.boolean();
    hours.push(oh);
  }
  return hours;
}

export function formatDate(d: Date): string {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as const;
  return d.toLocaleDateString('en-us', options);
}

export function formatDateAsMonthDayYear(d: Date): string {
  const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
  return d.toLocaleDateString('en-us', options);
}

export function getNationalPhoneCode(countryCode: string) {
  const countriesData: any = countryData;
  if (countriesData[countryCode] && countriesData[countryCode].callingCode) {
    return countriesData[countryCode].callingCode;
  }
  return '1';
}

export function convertMinorUnitToCurrency(amount: any) {
  if (amount) {
    const amountNumber = _.toNumber(amount);
    return (amountNumber * 0.01).toFixed(2);
  }
  return amount;
}

export function convertCurrencyToMinorUnit(amount: any) {
  if (amount) {
    const amountNumber = _.toNumber(amount);
    return Math.floor(amountNumber * 100);
  }
  return amount;
}

export function handleKeyDownOnlyWholeNumeric(event: any) {
  const checkChars = ['e', 'E', '.', '-', '+'];
  if (checkChars.includes(event.key)) {
    event.preventDefault();
  }
}

export function handleKeyDownOnlyNumeric(event: any) {
  const checkChars = ['e', 'E', '-', '+'];
  if (checkChars.includes(event.key)) {
    event.preventDefault();
  }
}

export function handleKeyDownValidPhoneCharacter(event: any) {
  const a = _.toString(event.key);
  const isLetter = a.length === 1;
  const isMetaKey = event.altKey || event.ctrlKey || event.metaKey;

  if (isLetter && /[a-wy-zA-Z]/.test(a) && !isMetaKey) {
    event.preventDefault();
  }
}

export function handleKeyDownPreventDefault(event: any) {
  event.preventDefault();
}

export function getErrorText(status: number): string {
  switch (status) {
    case 401:
      return 'Unauthenticated';
    case 404:
      return 'Not Found';
    case 409:
      return 'Data out of date. Please refresh page.';
    case 500:
      return 'Internal error. Try again later';
    default:
      // todo send message to backend
      return 'Internal error';
  }
}

export function capitalizeGeneric(value: string): string {
  return value && _.isString(value)
    ? value
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
    : '';
}

export function isEmpty(obj: any): boolean {
  return _.isEmpty(obj);
}

export function findCountryName(countryCode: string): string {
  const country = appModule.CountryData.find((c) => c.code === countryCode);

  if (country) {
    return country.name;
  }
  return '';
}

export function filterCodeAndName(item: CountryData, queryText: string): boolean {
  return (
    (_.isString(item.name) &&
      item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1) ||
    (_.isString(item.code) &&
      item.code.toLowerCase().indexOf(queryText.toLowerCase()) > -1)
  );
}

export const timezones = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Asmera',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Timbuktu',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Atka',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Fort_Wayne',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Valparaiso',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Knox_IN',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port_of_Spain',
  'America/Port-au-Prince',
  'America/Porto_Acre',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Shiprock',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Virgin',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/South_Pole',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Ashkhabad',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Choibalsan',
  'Asia/Chongqing',
  'Asia/Chungking',
  'Asia/Colombo',
  'Asia/Dacca',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Gaza',
  'Asia/Harbin',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Istanbul',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kashgar',
  'Asia/Kathmandu',
  'Asia/Katmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macao',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qyzylorda',
  'Asia/Rangoon',
  'Asia/Riyadh',
  'Asia/Saigon',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Tel_Aviv',
  'Asia/Thimbu',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Ujung_Pandang',
  'Asia/Ulaanbaatar',
  'Asia/Ulan_Bator',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faeroe',
  'Atlantic/Faroe',
  'Atlantic/Jan_Mayen',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/ACT',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Canberra',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/LHI',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/North',
  'Australia/NSW',
  'Australia/Perth',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Sydney',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West',
  'Australia/Yancowinna',
  'Brazil/Acre',
  'Brazil/DeNoronha',
  'Brazil/East',
  'Brazil/West',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/East-Saskatchewan',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
  'Chile/Continental',
  'Chile/EasterIsland',
  'Cuba',
  'Egypt',
  'Eire',
  'Etc/GMT',
  'Etc/GMT+0',
  'Etc/UCT',
  'Etc/Universal',
  'Etc/UTC',
  'Etc/Zulu',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Athens',
  'Europe/Belfast',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Nicosia',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Tiraspol',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'GB',
  'GB-Eire',
  'GMT',
  'GMT+0',
  'GMT0',
  'GMT-0',
  'Greenwich',
  'Hongkong',
  'Iceland',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Iran',
  'Israel',
  'Jamaica',
  'Japan',
  'Kwajalein',
  'Libya',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
  'Navajo',
  'NZ',
  'NZ-CHAT',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Ponape',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Samoa',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Truk',
  'Pacific/Wake',
  'Pacific/Wallis',
  'Pacific/Yap',
  'Poland',
  'Portugal',
  'PRC',
  'ROC',
  'ROK',
  'Singapore',
  'Turkey',
  'UCT',
  'Universal',
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/Eastern',
  'US/East-Indiana',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Samoa',
  'UTC',
  'W-SU',
  'Zulu',
];
